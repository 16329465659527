import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/input',
  //   name: 'Input',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "input" */ '../views/Input.vue')
  // },
  // {
  //   path: '/result',
  //   name: 'Result',
  //   component: () => import('../views/Result.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
