var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module monitoring"},[_c('h3',[_vm._v("Monitoring")]),_c('div',[_c('label',[_vm._v(" Kube Prometheus Stack* "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" far fa-question-circle ")])]}}])},[_c('span',[_vm._v("Deploy the prometheus-community/kube-prometheus-stack Helm chart alongside K8ssandra.")])])],1),_c('v-switch',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" False ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" True ")]},proxy:true}]),model:{value:(_vm.kube_prometheus),callback:function ($$v) {_vm.kube_prometheus=$$v},expression:"kube_prometheus"}})],1),_c('div',[_c('label',[_vm._v(" Service Monitors* "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" far fa-question-circle ")])]}}])},[_c('span',[_vm._v("Deploy prometheus-operator ServiceMonitor Custom Resources for monitoring the K8ssandra cluster.")])])],1),_c('v-switch',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" False ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" True ")]},proxy:true}]),model:{value:(_vm.service_monitors),callback:function ($$v) {_vm.service_monitors=$$v},expression:"service_monitors"}})],1),_c('div',[_c('label',[_vm._v(" Dashboards "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" far fa-question-circle ")])]}}])},[_c('span',[_vm._v("Deploy preconfigured Grafana Dashboards via Kubernetes ConfigMap objects.")])])],1),_c('v-switch',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" False ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" True ")]},proxy:true}]),model:{value:(_vm.dashboards),callback:function ($$v) {_vm.dashboards=$$v},expression:"dashboards"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }