var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"node_config"},[_c('h4',[_vm._v("Node Config")]),_c('div',[_c('label',[_vm._v("CPU Cores*")]),_c('v-slider',{attrs:{"min":"100","max":"32000","thumb-label":"always","track-color":"#b2becd","step":"100"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" 100 milliCPU ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" 32000 milliCPU ")]},proxy:true}]),model:{value:(_vm.cpu_number),callback:function ($$v) {_vm.cpu_number=_vm._n($$v)},expression:"cpu_number"}})],1),_c('span',{staticClass:"help-text"},[_vm._v("1000m = 1vCPU")]),_c('div',[_c('label',[_vm._v("RAM")]),_c('v-slider',{attrs:{"min":"2","max":"256","thumb-label":"always","track-color":"#b2becd"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" 2 GB ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" 256 GB ")]},proxy:true}]),model:{value:(_vm.ram_number),callback:function ($$v) {_vm.ram_number=_vm._n($$v)},expression:"ram_number"}})],1),_c('div',[_c('v-combobox',{attrs:{"items":_vm.storage_classes,"dense":"","minlength":"3","type":"text","append-icon":"fa-chevron-down"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('label',[_vm._v(" Storage Class* "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" far fa-question-circle ")])]}}])},[_c('span',[_vm._v("K8ssandra recommends a storage class with the VolumeBindingMode set to WaitForFirstConsumer. If you need to check this consider running `kubectl get storageclasses` against your cluster.")])])],1)]},proxy:true}]),model:{value:(_vm.storage_class),callback:function ($$v) {_vm.storage_class=$$v},expression:"storage_class"}})],1),_c('div',[_c('v-text-field',{attrs:{"suffix":"GB","rules":[_vm.rules.integers],"hint":"Max 4000GB; Integers only","persistent-hint":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('label',[_vm._v(" Storage Amount "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" far fa-question-circle ")])]}}])},[_c('span',[_vm._v("Generally you want to target 1-2 TB of active data per node with additional capacity for overhead purposes.")])])],1)]},proxy:true}]),model:{value:(_vm.storage_number),callback:function ($$v) {_vm.storage_number=_vm._n($$v)},expression:"storage_number"}})],1),_c('div',{staticClass:"separator"}),_c('h4',[_vm._v("Advanced Settings")]),_c('div',{staticClass:"advanced"},[_c('label',[_vm._v(" Heap Amount "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" far fa-question-circle ")])]}}])},[_c('span',[_vm._v("Heap is a portion of the total ram dedicated to each node. There are a number of off-heap objects and cache which may lead to instability if this field is set too high. Additionally more heap tends to lead to longer garbage collections.")])])],1),_c('v-slider',{attrs:{"min":"1","max":_vm.max_heap,"thumb-label":"always","track-color":"#b2becd"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" 1 GB ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.max_heap)+" GB ")]},proxy:true}]),model:{value:(_vm.heap_number),callback:function ($$v) {_vm.heap_number=_vm._n($$v)},expression:"heap_number"}}),_c('span',[_vm._v("Max value based on 50% of RAM. 25% of total RAM is recommended.")])],1),_c('div',[_c('additionalSeeds')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }